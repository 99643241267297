<template>
  <div :class="isMobile?'mobheader':'subheader'">
    <!-- <p><strong>Complete your profile:</strong><span>Upload resume</span><span>Upload visume</span></p> -->
    <!-- <div class="alert alert-danger alert-dismissible fade show" role="alert" dismissible>
      <strong>Complete your profile:</strong><span class="ml-3" role="button" v-if="!this.$cookies.get('hire-seeker').resume_path" v-on:click="upload('resume')">Upload resume</span><span class="ml-3" role="button" v-if="!this.$cookies.get('hire-seeker').visume_path" v-on:click="upload('visume')">Upload visume</span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->
    <b-alert show variant="danger" class="mb-0" dismissible>
      <strong>Complete your profile:</strong><span class="ml-3" role="button" v-if="!this.$cookies.get('hire-seeker').resume_path" v-on:click="upload('resume')">Upload resume</span><span class="ml-3" role="button" v-if="!this.$cookies.get('hire-seeker').visume_path" v-on:click="upload('visume')">Upload visume</span>
    </b-alert>
    <VisumeModal :visume="this.$cookies.get('hire-seeker').visume_path" :open="visumeModal.open" v-if="this.$cookies.get('hire-seeker')"/>
    <b-modal ref="upload-resume" title="Upload Resume" hide-footer>
      <div class="container">
        <v-form ref="form" lazy-validation v-model="valid">
          <v-file-input accept=".doc,.docx,.rtf,.pdf"  v-model="resume" placeholder="Post resume" append-icon="mdi-file" prepend-icon="" :rules="[v => !!v || 'Upload valid resume']"  show-size outlined></v-file-input>
        </v-form>
      </div>
      <template>
        <div class="float-right">
          <b-button class="mr-2" size="sm" v-on:click.prevent="closeModal('upload-resume')">Cancel</b-button>
          <b-button size="sm" variant="success" v-on:click.prevent="uploadResume()" v-if="!loading">Save</b-button>
          <b-button size="sm" variant="success" v-if="loading">...</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
const VisumeModal = () => import(/* webpackPrefetch: true */ "@/components/VisumeModal");

import AwsUpload from "../../utils/s3upload"
import Actions from '../libraries/apiActions.js';
export default{
  components:{
    VisumeModal
  },
  data(){
    return {
      isMobile:false,
      visumeModal:{
        open:'exit',
      },
      visume:'',
      action:new Actions(),
      resume_path:null,
      resume: null,
      update:{
        resume:null
      },
      valid:true,
      loading:false
    }
  },
  beforeMount(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
  },
  methods:{
    openModal : function(type= ''){
      this.open = type == "" ? this.open : type;
      this.visumeModal.open = 'exit';
      this.visumeModal.open = type;
    },
    closeModal(data){
      this.$refs[data].hide();
      if(data == 'profile-alert'){
        let cookie = this.$cookies.get('hire-seeker');
        let updated_cookie = {token:cookie.token,name:cookie.name,visume_path:cookie.visume_path,resume_path:cookie.resume_path,alert:'no'};
        this.$cookies.remove("hire-seeker");
        this.$cookies.set("hire-seeker",updated_cookie);
      }
    },
    upload(data){
      if(data == 'visume'){
        this.openModal('visume');
      }else if(data == 'resume'){
        this.$refs['upload-resume'].show();
      }
    },
    async uploadResume(){
      let token = this.$cookies.get("hire-seeker").token;
      this.valid = this.$refs.form.validate();
      var self = this;
      if(this.valid == true){
        this.loading = true;
        const file = this.resume;
        const awsUploader = new AwsUpload();
          try {
            var extension = this.resume.name.split('.')[this.resume.name.split('.').length-1]
            var d = new Date();
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
            var timestamp = Math.floor(Date.now() / 1000);
            var filename = 'resume/'+ current_date+"/"+self.$cookies.get('hire-seeker').name + timestamp + "."+extension;
            const response = await awsUploader.uploadToS3(file, filename);
            this.update.resume = response.url;
            this.action.updateResume(this.update.resume,token).then(data =>{
              if(data.message == "Resume Updated"){
                this.loading = false;
                this.resume = false;
                this.$refs['upload-resume'].hide();
                let cookie = this.$cookies.get('hire-seeker');
                let updated_cookie = {token:cookie.token,name:cookie.name,visume_path:cookie.visume_path,resume:'yes',resume_path:this.update.resume,alert:cookie.alert};
                this.$cookies.remove("hire-seeker");
                this.$cookies.set("hire-seeker",updated_cookie);
                location.reload();
              }else{
                this.loading = false;
              }
            }).catch(err =>{
              this.loading = false;
              console.log(err);
            })
          } catch (error) {
            console.error('Failed to upload file to S3:', error);
          }

      }
    }
  }
}
</script>
<style scoped>
.subheader{
  position: relative;
  top: 80px;
  /* background:#dedede; */
  /* margin: 80px 0 -100px 0; */
}
.mobheader{
  position: relative;
  top: 100px;
  /* margin: 100px 0 -110px 0; */
}
</style>